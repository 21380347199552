import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import ButtonBase from "@material-ui/core/ButtonBase"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import priceFormat from "../../helperFunc/priceFormat"

import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  butSolidBlack: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "0.3rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  butSolidGrey: {
    backgroundColor: "transparent",
    border: theme.palette.common.grey,
    borderWidth: "0.1rem",
    borderStyle: "solid",

    color: theme.palette.common.grey,
    padding: "0.2rem",
  },

  butSolidDisabled: {
    border: theme.palette.common.offWhite,
    borderWidth: "0.1rem",
    borderStyle: "solid",
    padding: "0.2rem",
    backgroundColor: `${theme.palette.common.offWhite} !important`,
  },
  mainGrid: {
    paddingBottom: "0.2rem",
    paddingTop: "0.2rem",
  },

  textFieldNumberContainer: {
    maxWidth: "2rem",
  },

  iconSize: {
    fontSize: "1.2rem !important",
  },

  descriptionText: {
    color: theme.palette.common.grey,
    lineHeight: "1.3rem",
    marginLeft: "0.5rem",
    marginRight: "2rem",
  },

  textFieldNumber: {
    color: theme.palette.primary.main,
    fontSize: "1.25rem",
    verticalAlign: "middle",
    backgroundColor: "transparent",
    borderRadius: "3rem",
    textAlignLast: "center",
    width: "2rem",
    borderWidth: 0,
    marginTop: 0,
    paddingBottom: "0.1rem",
    pointerEvents: "none",
    "&:focus-visible": {
      outline: 0,
    },
  },

  boxLabel: {
    paddingLeft: "0.5rem",
    marginRight: "2rem",
    marginTop: "0.2rem",
    fontSize: "1rem",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
    cursor: "pointer",
  },

  boxLabelDisabled: {
    paddingLeft: "0.5rem",
    marginRight: "2rem",
    marginTop: "0.2rem",
    fontSize: "1rem",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
    color: theme.palette.common.seventyWhite,
  },

  mainContainer: {
    borderRadius: "3rem",
  },

  TextField: {
    width: "100%",
  },
  checkBoxContainer: {
    flexWrap: "nowrap",
  },

  checkBoxLabel: {
    fontSize: "1rem",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
  },
  checkBox: {
    padding: "0.5rem",
  },

  mainBox: {
    width: "100%",
    paddingRight: "1.5rem",
    textAlign: "inherit",
    minHeight: "3rem",
  },
  mainOutterGrid: {
    paddingLeft: "1.5rem",
  },
  counterGrid: {
    paddingRight: "0.5rem",
  },

  priceContainer: {
    textAlign: "end",
  },
}))

function FieldCounter({
  onChange,
  value,
  inputRef,
  setValue,
  name,
  label,
  description,
  disabled,
  updateFormData,
  optionIndex,
  fieldData,
}) {
  const counterChangeHandler = (value, addOrSub) => {
    //console.log(name)
    //console.log(value)
    updateFormData(value + addOrSub, optionIndex, addOrSub)
  }

  const handleIncrement = () => {
    if (value < 99) {
      setValue(name, value + 1)
      counterChangeHandler(value, +1)
    }
  }

  const handleDecrement = () => {
    if (value > 0) {
      setValue(name, value - 1)
      counterChangeHandler(value, -1)
    }
  }
  const classes = useStyles()

  return (
    <Grid item classes={{ root: classes.mainOutterGrid }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        classes={{ root: classes.checkBoxContainer }}
      >
        <Grid item classes={{ root: classes.counterGrid }}>
          <Grid
            container
            direction="row"
            alignItems="baseline"
            wrap="nowrap"
            classes={{ root: classes.mainGrid }}
          >
            <Grid item>
              <Box classes={{ root: classes.mainContainer }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                >
                  {value > 0 ? (
                    <>
                      <Grid item>
                        <IconButton
                          onClick={handleDecrement}
                          classes={{ root: classes.butSolidBlack }}
                        >
                          <RemoveIcon classes={{ root: classes.iconSize }} />
                        </IconButton>
                      </Grid>
                      <Grid
                        xs
                        item
                        classes={{ root: classes.textFieldNumberContainer }}
                      >
                        <input
                          ref={inputRef}
                          className={classes.textFieldNumber}
                          value={value}
                          onChange={onChange}
                          readOnly
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item>
                    <IconButton
                      onClick={handleIncrement}
                      classes={{
                        root: clsx({
                          [classes.butSolidGrey]: value < 1,
                          [classes.butSolidBlack]: value >= 1,
                        }),
                        disabled: classes.butSolidDisabled,
                      }}
                      disabled={disabled}
                    >
                      <AddIcon classes={{ root: classes.iconSize }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <ButtonBase
            classes={{ root: classes.mainBox }}
            onClick={disabled ? undefined : handleIncrement}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  classes={{
                    root: clsx({
                      [classes.boxLabelDisabled]: disabled,
                      [classes.boxLabel]: !disabled,
                    }),
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  variant="body1"
                  classes={{ root: classes.descriptionText }}
                >
                  {description}
                </Typography>
              </Grid>
              <Grid item classes={{ root: classes.priceContainer }}>
                <Typography variant="body1">
                  {priceFormat(
                    fieldData.optionPrice,
                    fieldData.optionPriceInfo
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FieldCounter
