import React from "react"
import { getInitialState } from "./formHelperFunc/formHelperFunc"

const FormInitialState = ({
  children,
  errors,
  setErrors,
  data,
  setIsAllValid,
  setOrderSelectedItems,
  isSavedData,
}) => {
  React.useEffect(() => {
    if (isSavedData !== true) {
      getInitialState(
        setIsAllValid,
        data,
        setOrderSelectedItems,
        errors,
        setErrors
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

export default FormInitialState
