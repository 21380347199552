import React from "react"

import FormGroup from "@material-ui/core/FormGroup"
import { Controller } from "react-hook-form"

import FieldCounter from "./fieldCounter"

const QuantityBoxField = ({
  control,
  setValue,
  fieldData,
  fieldName,
  numberOfSelected,
  maxNumbOfSelections,
  updateFormData,
  defaultOption,
  optionIndex,
  fieldVisChanged,
}) => {
  let fieldCombName = `${fieldName}${
    fieldData.optionName ? `-${fieldData.optionName}` : ``
  }`

  return (
    <>
      <FormGroup>
        <Controller
          name={fieldCombName}
          control={control}
          defaultValue={
            fieldData.savedData && !fieldVisChanged
              ? fieldData.savedData
              : defaultOption === fieldData.optionName
              ? 1
              : 0
          }
          render={({ field: { onChange, value, name, ref } }) => (
            <FieldCounter
              name={name}
              inputRef={ref}
              disabled={
                numberOfSelected >= maxNumbOfSelections &&
                maxNumbOfSelections > 0
                  ? true
                  : false
              }
              optionIndex={optionIndex}
              onChange={onChange}
              value={value}
              setValue={setValue}
              label={fieldData.optionDigitalLabel}
              description={fieldData.optionDescription}
              updateFormData={updateFormData}
              fieldData={fieldData}
            />
          )}
        ></Controller>
      </FormGroup>
    </>
  )
}

export default QuantityBoxField
