import React from "react"
import TextField from "@material-ui/core/TextField"

const TextBoxField = ({
  error,
  inputRef,
  onChange,
  value,
  formData,
  updateTextFieldFunc,
}) => {
  let maxNumberofChars = formData.maxNumbOfSelections
    ? parseInt(formData.maxNumbOfSelections)
    : 200
  return (
    <TextField
      error={error ? true : false}
      ref={inputRef}
      onChange={onChange}
      onInput={e => {
        e.target.value = e.target.value.slice(0, maxNumberofChars)
      }}
      onBlur={value => {
        updateTextFieldFunc(value.target.value)
      }}
      value={value}
      multiline
      maxRows={4}
      label={formData.fieldDigitalLabel}
      inputProps={{
        maxLength: maxNumberofChars,
      }}
      helperText={`${maxNumberofChars} max characters`}
    />
  )
}

export default TextBoxField
