import * as React from "react"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  butSolidWhite: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: "0.2rem",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  rightBut: {
    marginLeft: "0.3rem",
  },

  leftBut: {
    marginRight: "0.3rem",
  },
  textFieldNumberContainer: {
    maxWidth: "2.3rem",
  },
  textFieldNumber: {
    color: theme.palette.common.white,
    fontSize: "1.2rem",
    verticalAlign: "middle",
    backgroundColor: "transparent",
    borderRadius: "3rem",
    textAlign: "center !important",
    width: "2.3rem",
    borderWidth: 0,
    marginTop: 0,
    padding: 0,
    marginBottom: ".1rem",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    "&:focus-visible": {
      outline: 0,
    },
  },
  textFieldNumberDisabled: {
    color: theme.palette.common.grey,
    fontSize: "1.2rem",
    verticalAlign: "middle",
    backgroundColor: "transparent",
    borderRadius: "3rem",
    textAlign: "center !important",
    width: "2.3rem",
    borderWidth: 0,
    marginTop: 0,
    marginBottom: ".1rem",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    "&:focus-visible": {
      outline: 0,
    },
  },

  mainContainer: {
    borderRadius: "3rem",
  },
  butSolidWhiteDisabled: {
    background: `${theme.palette.common.grey} !important`,
  },
}))

function Counter({ quantity, setQuantity, disabled }) {
  const handleIncrement = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }
  const classes = useStyles()

  return (
    <Box classes={{ root: classes.mainContainer }}>
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          classes={{
            root: classes.leftBut,
          }}
        >
          <IconButton
            onClick={handleDecrement}
            classes={{
              root: classes.butSolidWhite,
              disabled: classes.butSolidWhiteDisabled,
            }}
            disabled={disabled}
          >
            <RemoveIcon />
          </IconButton>
        </Grid>
        <Grid xs item classes={{ root: classes.textFieldNumberContainer }}>
          <input
            className={
              disabled
                ? classes.textFieldNumberDisabled
                : classes.textFieldNumber
            }
            value={quantity}
            readOnly
          />
        </Grid>
        <Grid
          item
          classes={{
            root: classes.rightBut,
          }}
        >
          <IconButton
            onClick={handleIncrement}
            classes={{
              root: classes.butSolidWhite,
              disabled: classes.butSolidWhiteDisabled,
            }}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Counter
