import * as React from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
//import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "1.2rem",
    paddingBottom: "1.2rem",
    width: "100%",
  },
  mainBox: {
    zIndex: 200,
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("sm")]: {
      left: 0,
      borderRadius: 0,
      position: "fixed",
    },
  },
  textCol: {
    color: theme.palette.common.seventyWhite,
  },
}))

function ProductFooter({ children }) {
  const classes = useStyles()

  return (
    <Paper classes={{ root: classes.mainBox }} elevation={0}>
      <Container classes={{ root: classes.mainContainer }}>
        {children}
      </Container>
    </Paper>
  )
}

export default ProductFooter
