import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    cursor: "default !important",
    backgroundColor: theme.palette.common.almostWhite,
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "0.5rem",
  },
  formContainer: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  headerContainerRed: {
    backgroundColor: theme.palette.common.aletBg,
  },
  headerContentContainer: {
    marginTop: "0.5rem !important",
    marginBottom: "0.5rem !important",
  },
  detailsContainer: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },

  headerText: {
    fontSize: "1.2rem",
  },
  requiredChip: {
    backgroundColor: theme.palette.common.alertIcon,
    marginRight: "0.5rem",
    marginTop: 0,
    marginBottom: "0.2rem",
  },

  requiredChipSelected: {
    marginRight: "0.5rem",
    marginTop: 0,
    marginBottom: "0.2rem",
    backgroundColor: theme.palette.common.offWhite,
    color: theme.palette.common.seventyWhite,
  },
  radio: {
    padding: "0.5rem",
  },
  headerDetailsText: { marginBottom: 0, fontSize: "0.9rem" },
}))

export const getFieldName = (fieldName, optionsData, k) => {
  return `${fieldName}${
    optionsData[k].optionName ? `-${optionsData[k].optionName}` : ``
  }`
}

const addSingleDefaultOption = (
  dataCopy,
  errorsCopy,
  orderSelectedItemsCopy,
  isVisibleCopy,
  fieldIndex
) => {
  let count = 0
  let newSelectedItems = []

  let opIndex = dataCopy.optionsData.findIndex(
    element => element.optionName === dataCopy.defaultOption
  )
  if (dataCopy.optionsData[opIndex].optionName) {
    newSelectedItems.push({
      optionName: dataCopy.optionsData[opIndex].optionName,
      optionDigitalLabel: dataCopy.optionsData[opIndex].optionDigitalLabel,
      optionPrintLabel: dataCopy.optionsData[opIndex].optionPrintLabel,
      optionPrice: dataCopy.optionsData[opIndex].optionPrice,
      optionIndex: opIndex,
      optionId: `${
        dataCopy.isSharedField
          ? dataCopy.optionsData[opIndex].strapiId
          : dataCopy.optionsData[opIndex].id
      }`,
      quantity: 1,
    })
  }
  count++

  if (newSelectedItems.length > 0) {
    orderSelectedItemsCopy[dataCopy.fieldName] = {
      fieldDigitalLabel: dataCopy.fieldDigitalLabel,
      fieldPrintLabel: dataCopy.fieldPrintLabel,
      fieldPrice: dataCopy.fieldPrice,
      fieldId: `${dataCopy.strapiId}`,
      fieldOrder: dataCopy.fieldOrder,
      isSharedField: dataCopy.isSharedField,
      fieldType: dataCopy.fieldType,
      selectedItems: newSelectedItems,
    }
  }

  errorsCopy.count = count
  if (
    (count > 0 && dataCopy.isRequired) ||
    !dataCopy.isRequired ||
    !isVisibleCopy[fieldIndex]
  ) {
    errorsCopy.isValid = true
    errorsCopy.errorMsg = null
  } else {
    errorsCopy.isValid = false
  }
}

const removeConditionField = (
  dataCopy,
  errorsCopy,
  setValue,
  orderSelectedItemsCopy,
  isVisibleCopy,
  removeFieldIndex
) => {
  if (
    dataCopy.fieldType === "quantityBox" ||
    dataCopy.fieldType === "checkBox"
  ) {
    for (let k = 0; dataCopy.optionsData.length > k; k++) {
      setValue(
        getFieldName(dataCopy.fieldName, dataCopy.optionsData, k),
        dataCopy.fieldType === "quantityBox"
          ? dataCopy.defaultOption === dataCopy.optionsData[k].optionName
            ? 1
            : 0
          : dataCopy.defaultOption === dataCopy.optionsData[k].optionName
          ? true
          : false
      )
    }
  } else if (dataCopy.fieldType === "radio") {
    setValue(
      dataCopy.fieldName,
      dataCopy.defaultOption
        ? `${dataCopy.fieldName}-${dataCopy.defaultOption}`
        : null
    )
  } else if (dataCopy.fieldType === "textField") {
    setValue(dataCopy.fieldName, "")
  }
  delete orderSelectedItemsCopy[dataCopy.fieldName]
  isVisibleCopy[removeFieldIndex] = false
  errorsCopy.count = 0
  errorsCopy.isValid = true
}

export const getErrorsArray = (dataCopy, errorsCopy, isVisibleCopy) => {
  let isAllValid = true
  for (let nn = 0; dataCopy.length > nn; nn++) {
    if (
      (errorsCopy[nn].count > 0 && dataCopy[nn].isRequired) ||
      !dataCopy[nn].isRequired ||
      !isVisibleCopy[nn]
    ) {
      errorsCopy[nn].isValid = true
      errorsCopy[nn].errorMsg = null
    } else {
      errorsCopy[nn].isValid = false
      isAllValid = false
    }
  }

  return isAllValid
}

export const sort = dataIn => {
  let sortable = []
  for (let item in dataIn) {
    sortable.push([item, dataIn[item]])
  }

  sortable.sort((a, b) => (a[1].fieldOrder > b[1].fieldOrder ? 1 : -1))
  let objSorted = {}
  sortable.forEach(function (item) {
    objSorted[item[0]] = item[1]
  })

  return objSorted
}

export const getInitialState = (
  setIsAllValid,
  data,
  setOrderSelectedItems,
  errors,
  setErrors
) => {
  let orderSelectedItemsCopy = {}
  let errorsCopy = [...errors]
  let isAllValid = true

  for (let nn = 0; nn < data.length; nn++) {
    let count = 0
    let newSelectedItems = []
    if (data[nn].isVisible) {
      if (data[nn].defaultOption) {
        let opIndex = data[nn].optionsData.findIndex(
          element => element.optionName === data[nn].defaultOption
        )
        if (data[nn].optionsData[opIndex].optionName) {
          newSelectedItems.push({
            optionName: data[nn].optionsData[opIndex].optionName,
            optionDigitalLabel:
              data[nn].optionsData[opIndex].optionDigitalLabel,
            optionPrintLabel: data[nn].optionsData[opIndex].optionPrintLabel,
            optionPrice: data[nn].optionsData[opIndex].optionPrice,
            optionIndex: opIndex,
            optionId: `${
              data[nn].isSharedField
                ? data[nn].optionsData[opIndex].strapiId
                : data[nn].optionsData[opIndex].id
            }`,
            quantity: 1,
          })
        }
        count++
      }

      if (newSelectedItems.length > 0) {
        orderSelectedItemsCopy[data[nn].fieldName] = {
          fieldDigitalLabel: data[nn].fieldDigitalLabel,
          fieldPrintLabel: data[nn].fieldPrintLabel,
          fieldPrice: data[nn].fieldPrice,
          fieldId: `${data[nn].strapiId}`,
          fieldOrder: data[nn].fieldOrder,
          isSharedField: data[nn].isSharedField,
          fieldType: data[nn].fieldType,
          selectedItems: newSelectedItems,
        }
      }
    }
    errorsCopy[nn].count = count
    if (
      (count > 0 && data[nn].isRequired) ||
      !data[nn].isRequired ||
      !data[nn].isVisible
    ) {
      errorsCopy[nn].isValid = true
      errorsCopy[nn].errorMsg = null
    } else {
      errorsCopy[nn].isValid = false
      isAllValid = false
    }
  }

  setIsAllValid(isAllValid)
  setErrors(errorsCopy)
  setOrderSelectedItems(orderSelectedItemsCopy)

  return true
}

export const updateBox = (
  value,
  optionIndex,
  setIsAllValid,
  orderSelectedItems,
  formData,
  data,
  setOrderSelectedItems,
  errors,
  setErrors,
  setValue,
  fieldIndex,
  addOrSub,
  isVisible,
  setIsVisible
) => {
  let orderSelectedItemsCopy = { ...orderSelectedItems }
  let count = 0
  let newSelectedItems = []

  let dataCopy = [...data]
  let errorsCopy = [...errors]
  let isVisibleCopy = [...isVisible]
  let changeData = false
  let isAllValid = true

  let selectedItemIndex = -1
  if (orderSelectedItemsCopy[formData.fieldName]) {
    newSelectedItems = [
      ...orderSelectedItemsCopy[formData.fieldName].selectedItems,
    ]
    selectedItemIndex = newSelectedItems.findIndex(
      element => element.optionIndex === optionIndex
    )
  }

  if (!value || value < 1) {
    if (selectedItemIndex > -1) {
      newSelectedItems.splice(selectedItemIndex, 1)
    }
    let oldIndex = optionIndex
    if (formData.optionsData[oldIndex].optionConditions) {
      for (let j = 0; dataCopy.length > j; j++) {
        if (
          formData.optionsData[oldIndex].optionConditions.includes(
            dataCopy[j].fieldName
          )
        ) {
          if (isVisibleCopy[j]) {
            removeConditionField(
              dataCopy[j],
              errorsCopy[j],
              setValue,
              orderSelectedItemsCopy,
              isVisibleCopy,
              j
            )
            changeData = true
          }
        }
      }
    }
    count = errorsCopy[fieldIndex].count - 1
  } else {
    let newIndex = optionIndex
    if (
      formData.optionsData[newIndex].optionName &&
      isVisibleCopy[fieldIndex]
    ) {
      let newOptionData = {
        optionName: formData.optionsData[newIndex].optionName,
        optionDigitalLabel: formData.optionsData[newIndex].optionDigitalLabel,
        optionPrintLabel: formData.optionsData[newIndex].optionPrintLabel,
        optionPrice: formData.optionsData[newIndex].optionPrice,
        optionIndex: newIndex,
        optionId: `${
          formData.isSharedField
            ? formData.optionsData[newIndex].strapiId
            : formData.optionsData[newIndex].id
        }`,
        quantity: formData.fieldType === "quantityBox" ? parseInt(value) : 1,
      }
      if (selectedItemIndex > -1) {
        newSelectedItems[selectedItemIndex] = newOptionData
      } else {
        newSelectedItems.push(newOptionData)
      }
    }

    if (formData.optionsData[newIndex].optionConditions) {
      for (let j = 0; dataCopy.length > j; j++) {
        if (
          formData.optionsData[newIndex].optionConditions.includes(
            dataCopy[j].fieldName
          )
        ) {
          if (!isVisibleCopy[j]) {
            isVisibleCopy[j] = true
            if (dataCopy[j].defaultOption) {
              addSingleDefaultOption(
                dataCopy[j],
                errorsCopy[j],
                orderSelectedItemsCopy,
                isVisibleCopy,
                j
              )
            }
            changeData = true
          }
        }
      }
    }

    if (addOrSub) {
      count = errorsCopy[fieldIndex].count + addOrSub
    } else {
      count = errorsCopy[fieldIndex].count + 1
    }
  }

  if (newSelectedItems.length > 0) {
    orderSelectedItemsCopy[formData.fieldName] = {
      fieldDigitalLabel: formData.fieldDigitalLabel,
      fieldPrintLabel: formData.fieldPrintLabel,
      fieldPrice: formData.fieldPrice,
      fieldId: `${formData.strapiId}`,
      fieldOrder: formData.fieldOrder,
      isSharedField: formData.isSharedField,
      fieldType: formData.fieldType,
      selectedItems: newSelectedItems,
    }
  } else if (
    newSelectedItems.length === 0 &&
    orderSelectedItemsCopy[formData.fieldName]
  ) {
    delete orderSelectedItemsCopy[formData.fieldName]
  }

  errorsCopy[fieldIndex].count = count
  isAllValid = getErrorsArray(dataCopy, errorsCopy, isVisibleCopy)

  if (changeData) {
    setIsVisible(isVisibleCopy)
  }
  setIsAllValid(isAllValid)
  setErrors(errorsCopy)
  setOrderSelectedItems(orderSelectedItemsCopy)

  return count
}

export const updateRadio = (
  optionIndex,
  setIsAllValid,
  fieldName,
  orderSelectedItems,
  formData,
  data,
  setOrderSelectedItems,
  errors,
  setErrors,
  setValue,
  fieldIndex,
  isVisible,
  setIsVisible
) => {
  let orderSelectedItemsCopy = { ...orderSelectedItems }
  let count = 0
  let newSelectedItems = []

  let dataCopy = [...data]
  let isVisibleCopy = [...isVisible]
  let errorsCopy = [...errors]
  let changeData = false
  let isAllValid = true

  if (orderSelectedItemsCopy[fieldName]?.selectedItems.length > 0) {
    let oldIndex =
      orderSelectedItemsCopy[fieldName].selectedItems[0].optionIndex
    if (formData.optionsData[oldIndex].optionConditions) {
      for (let j = 0; dataCopy.length > j; j++) {
        if (
          formData.optionsData[oldIndex].optionConditions.includes(
            dataCopy[j].fieldName
          )
        ) {
          if (isVisibleCopy[j]) {
            removeConditionField(
              dataCopy[j],
              errorsCopy[j],
              setValue,
              orderSelectedItemsCopy,
              isVisibleCopy,
              j
            )
            changeData = true
          }
        }
      }
    }
  }

  let newIndex = optionIndex
  if (formData.optionsData[newIndex].optionName && isVisibleCopy[fieldIndex]) {
    newSelectedItems.push({
      optionName: formData.optionsData[newIndex].optionName,
      optionDigitalLabel: formData.optionsData[newIndex].optionDigitalLabel,
      optionPrintLabel: formData.optionsData[newIndex].optionPrintLabel,
      optionPrice: formData.optionsData[newIndex].optionPrice,
      optionIndex: newIndex,
      optionId: `${
        formData.isSharedField
          ? formData.optionsData[newIndex].strapiId
          : formData.optionsData[newIndex].id
      }`,
      quantity: 1,
    })
  }

  if (formData.optionsData[newIndex].optionConditions) {
    for (let j = 0; dataCopy.length > j; j++) {
      if (
        formData.optionsData[newIndex].optionConditions.includes(
          dataCopy[j].fieldName
        )
      ) {
        if (!isVisibleCopy[j]) {
          isVisibleCopy[j] = true
          if (dataCopy[j].defaultOption) {
            addSingleDefaultOption(
              dataCopy[j],
              errorsCopy[j],
              orderSelectedItemsCopy,
              isVisibleCopy,
              j
            )
          }
          changeData = true
        }
      }
    }
  }

  count++

  if (newSelectedItems.length > 0) {
    orderSelectedItemsCopy[formData.fieldName] = {
      fieldDigitalLabel: formData.fieldDigitalLabel,
      fieldPrintLabel: formData.fieldPrintLabel,
      fieldPrice: formData.fieldPrice,
      fieldId: `${formData.strapiId}`,
      fieldOrder: formData.fieldOrder,
      isSharedField: formData.isSharedField,
      fieldType: formData.fieldType,
      selectedItems: newSelectedItems,
    }
  } else if (
    newSelectedItems.length === 0 &&
    orderSelectedItemsCopy[formData.fieldName]
  ) {
    delete orderSelectedItemsCopy[formData.fieldName]
  }

  errorsCopy[fieldIndex].count = count
  isAllValid = getErrorsArray(dataCopy, errorsCopy, isVisibleCopy)

  if (changeData) {
    setIsVisible(isVisibleCopy)
  }
  setIsAllValid(isAllValid)
  setErrors(errorsCopy)
  setOrderSelectedItems(orderSelectedItemsCopy)

  return count
}

export const updateText = (
  value,
  setIsAllValid,
  orderSelectedItems,
  formData,
  data,
  setOrderSelectedItems,
  errors,
  setErrors,
  fieldIndex,
  isVisible,
  setIsVisible
) => {
  let orderSelectedItemsCopy = { ...orderSelectedItems }
  let count = 0
  let newSelectedItems = []
  let dataCopy = [...data]
  let isVisibleCopy = [...isVisible]
  let errorsCopy = [...errors]
  let changeData = false
  let isAllValid = true
  let maxNumberofChars = formData.maxNumbOfSelections
    ? parseInt(formData.maxNumbOfSelections)
    : 200

  if (formData.fieldName && isVisibleCopy[fieldIndex] && value.length > 0) {
    newSelectedItems.push({
      optionName: "",
      optionDigitalLabel: "",
      optionPrintLabel: "",
      optionId: "",
      optionPrice: 0,
      quantity: 1,
      optionTextVal: value.slice(0, maxNumberofChars),
    })
    count++
  }
  if (newSelectedItems.length > 0) {
    orderSelectedItemsCopy[formData.fieldName] = {
      fieldDigitalLabel: formData.fieldDigitalLabel,
      fieldPrintLabel: formData.fieldPrintLabel,
      fieldPrice: formData.fieldPrice,
      fieldId: `${formData.strapiId}`,
      fieldOrder: formData.fieldOrder,
      isSharedField: formData.isSharedField,
      fieldType: formData.fieldType,
      selectedItems: newSelectedItems,
    }
  } else if (
    newSelectedItems.length === 0 &&
    orderSelectedItemsCopy[formData.fieldName]
  ) {
    delete orderSelectedItemsCopy[formData.fieldName]
  }

  errorsCopy[fieldIndex].count = count
  isAllValid = getErrorsArray(dataCopy, errorsCopy, isVisibleCopy)

  if (changeData) {
    setIsVisible(isVisibleCopy)
  }
  setIsAllValid(isAllValid)
  setErrors(errorsCopy)
  setOrderSelectedItems(orderSelectedItemsCopy)

  return count
}

export const setSavedData = (dataIn, copyData) => {
  let copyDataArray = []
  let dataInCopy = [...dataIn]

  for (let i = 0; Object.keys(copyData).length > i; i++) {
    copyDataArray.push({
      ...copyData[Object.keys(copyData)[i]],
      fieldName: Object.keys(copyData)[i],
    })
  }

  //console.log(dataInCopy, "dataInCopy")
  //console.log(copyDataArray, "copyDataArray")

  for (let i = 0; copyDataArray.length > i; i++) {
    let dataInIndex = dataInCopy.findIndex(
      element =>
        parseInt(element.strapiId) === parseInt(copyDataArray[i].fieldId)
    )

    if (dataInIndex > -1) {
      dataInCopy[dataInIndex].isVisible = true

      //if (copyDataArray[i].fieldType === "quantityBox") {
      //  for (let j = 0; copyDataArray[i].selectedItems.length > j; j++) {
      //    let optionIndex = dataInCopy[dataInIndex].optionsData.findIndex(
      //      element =>
      //        parseInt(element.strapiId) ===
      //        parseInt(copyDataArray[i].selectedItems[j].optionId)
      //    )
      //    if (optionIndex > -1) {
      //      dataInCopy[dataInIndex].optionsData[optionIndex].savedData =
      //        copyDataArray[i].selectedItems[j].quantity
      //    }
      //  }
      //}

      if (
        copyDataArray[i].fieldType === "quantityBox" ||
        copyDataArray[i].fieldType === "checkBox"
      ) {
        let savedCount = 0

        for (let j = 0; copyDataArray[i].selectedItems.length > j; j++) {
          let optionIndex = dataInCopy[dataInIndex].optionsData.findIndex(
            element =>
              parseInt(element.strapiId) ===
              parseInt(copyDataArray[i].selectedItems[j].optionId)
          )
          if (optionIndex > -1) {
            dataInCopy[dataInIndex].optionsData[optionIndex].savedData =
              copyDataArray[i].fieldType === "quantityBox"
                ? copyDataArray[i].selectedItems[j].quantity
                : true

            savedCount =
              savedCount +
              (copyDataArray[i].fieldType === "quantityBox"
                ? copyDataArray[i].selectedItems[j].quantity
                : 1)
          }
          dataInCopy[dataInIndex].savedCount = savedCount
        }
      } else if (copyDataArray[i].fieldType === "radio") {
        dataInCopy[dataInIndex].savedData =
          copyDataArray[i].selectedItems[0].optionName
        dataInCopy[dataInIndex].savedCount = 1
      } else if (copyDataArray[i].fieldType === "textField") {
        dataInCopy[dataInIndex].savedData =
          copyDataArray[i].selectedItems[0].optionTextVal
        dataInCopy[dataInIndex].savedCount = 1
      }
    }
  }

  dataInCopy.sort((a, b) => (a.fieldOrder > b.fieldOrder ? 1 : -1))
  let errorsArray = []
  let newIsVisible = []
  for (let i = 0; dataInCopy.length > i; i++) {
    newIsVisible.push(dataInCopy[i].isVisible)

    errorsArray[i] = {
      isValid: true,
      errorMsg: null,
      count: dataInCopy[i].savedCount ? dataInCopy[i].savedCount : 0,
    }
  }

  return { dataInCopy, newIsVisible, errorsArray }
}
