import React from "react"
import Grid from "@material-ui/core/Grid"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

import { makeStyles } from "@material-ui/core/styles"
import priceFormat from "../../helperFunc/priceFormat"

const useStyles = makeStyles(theme => ({
  TextField: {
    width: "100%",
  },
  checkBoxContainer: {
    flexWrap: "nowrap",
  },
  descriptionContainer: {
    paddingLeft: "30px",
    paddingRight: "5rem",
    marginTop: "-10px",
  },
  descriptionText: {
    color: theme.palette.common.grey,
    lineHeight: "1.3rem",
    paddingBottom: "0.25rem",
  },
  checkBoxLabel: {
    fontSize: "1rem",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
  },
  radio: {
    padding: "0.5rem",
  },

  mainBox: {
    width: "100%",
    justifyContent: "unset",
    display: "grid",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    minHeight: "3rem",
    textAlign: "inherit",
  },

  priceContainer: {
    textAlign: "end",
  },
}))

const RadioField = ({
  fieldData,
  fieldName,
  optionIndex,
  onChange,
  name,
  radioChangeHandler,
}) => {
  let fieldCombName = `${fieldName}${
    fieldData.optionName ? `-${fieldData.optionName}` : ``
  }`

  const radioChangeFunc = name => {
    radioChangeHandler(name, optionIndex)
  }

  const classes = useStyles()
  return (
    <ButtonBase
      onClick={e => {
        onChange(fieldCombName)
        radioChangeFunc(name)
      }}
      classes={{ root: classes.mainBox }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          classes={{ root: classes.checkBoxContainer }}
        >
          <Grid item>
            <FormControlLabel
              value={fieldCombName}
              control={
                <Radio
                  onChange={value => {
                    onChange(value)
                    radioChangeFunc(name)
                  }}
                  color="primary"
                  classes={{
                    root: classes.radio,
                  }}
                />
              }
              classes={{
                label: classes.checkBoxLabel,
              }}
              label={fieldData.optionDigitalLabel}
            />
          </Grid>
          <Grid item classes={{ root: classes.priceContainer }}>
            <Typography variant="body1">
              {priceFormat(fieldData.optionPrice, fieldData.optionPriceInfo)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          classes={{ root: classes.descriptionContainer }}
          wrap="nowrap"
        >
          <Grid item>
            <Typography
              variant="body1"
              classes={{ root: classes.descriptionText }}
            >
              {fieldData.optionDescription}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default RadioField
