const fieldStockFilter = (formDataIn, formStockData, idSelector) => {
  if (formDataIn) {
    let formFieldsIn = [...formDataIn]
    if (formStockData.length > 0 && formFieldsIn.length > 0) {
      for (let k = 0; k < formFieldsIn.length; k++) {
        let stock = formStockData.filter(
          optionsField =>
            parseInt(optionsField.id) === parseInt(formFieldsIn[k][idSelector])
        )
        if (stock.length > 0) {
          let newOptionsData = []
          for (let i = 0; i < formFieldsIn[k].optionsData.length; i++) {
            let fieldStock = stock[0].optionsData.filter(
              field =>
                parseInt(field.id) ===
                parseInt(formFieldsIn[k].optionsData[i][idSelector])
            )

            if (fieldStock[0].inStock !== false) {
              newOptionsData.push(formFieldsIn[k].optionsData[i])
            }
          }

          delete formFieldsIn[k].optionsData
          formFieldsIn[k].optionsData = newOptionsData
        }
      }
    }

    return formFieldsIn.filter(
      field => field.optionsData.length > 0 || field.fieldType === "textField"
    )
  } else {
    return []
  }
}

export default fieldStockFilter
