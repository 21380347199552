import React from "react"
import Grid from "@material-ui/core/Grid"
import clsx from "clsx"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import { Controller } from "react-hook-form"

import { makeStyles } from "@material-ui/core/styles"
import priceFormat from "../../helperFunc/priceFormat"

const useStyles = makeStyles(theme => ({
  mainBox: {
    width: "100%",
    justifyContent: "unset",
    display: "grid",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",

    textAlign: "inherit",
    minHeight: "3rem",
  },

  TextField: {
    width: "100%",
  },
  checkBoxContainer: {
    flexWrap: "nowrap",
  },
  descriptionContainer: {
    paddingLeft: "30px",
    paddingRight: "5rem",
    marginTop: "-10px",
  },
  descriptionText: {
    color: theme.palette.common.grey,
    lineHeight: "1.3rem",
    paddingBottom: "0.25rem",
  },
  checkBoxLabel: {
    fontSize: "1rem",
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
  },

  checkBoxLabelDisabled: {
    color: `${theme.palette.common.seventyWhite} !important`,
  },

  checkBox: {
    marginLeft: "-11px",
  },

  priceContainer: {
    textAlign: "end",
  },
}))

const CheckBoxField = ({
  control,
  fieldData,
  fieldName,
  numberOfSelected,
  maxNumbOfSelections,
  updateFormData,
  defaultOption,
  optionIndex,
  fieldVisChanged,
}) => {
  const boxChangeHandler = (value, not = false) => {
    updateFormData(not ? value : value.target.checked, optionIndex)
  }

  let fieldCombName = `${fieldName}${
    fieldData.optionName ? `-${fieldData.optionName}` : ``
  }`

  const classes = useStyles()
  return (
    <>
      <FormGroup>
        <Controller
          name={fieldCombName}
          control={control}
          defaultValue={
            fieldData.savedData && !fieldVisChanged
              ? fieldData.savedData
              : defaultOption === fieldData.optionName
              ? true
              : false
          }
          render={({ field: { onChange, value, name, ref } }) => (
            <ButtonBase
              onClick={e => {
                if (
                  !(
                    !value &&
                    numberOfSelected >= maxNumbOfSelections &&
                    maxNumbOfSelections > 0
                  )
                ) {
                  onChange(!value)
                  boxChangeHandler(!value, true)
                }
              }}
              classes={{ root: classes.mainBox }}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                  classes={{ root: classes.checkBoxContainer }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Checkbox
                          disabled={
                            !value &&
                            numberOfSelected >= maxNumbOfSelections &&
                            maxNumbOfSelections > 0
                              ? true
                              : false
                          }
                          inputRef={ref}
                          checked={!!value}
                          color="primary"
                          classes={{
                            root: classes.checkBox,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          classes={{
                            root: clsx(classes.checkBoxLabel, {
                              [classes.checkBoxLabelDisabled]:
                                !value &&
                                numberOfSelected >= maxNumbOfSelections &&
                                maxNumbOfSelections > 0,
                            }),
                          }}
                        >
                          {fieldData.optionDigitalLabel}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item classes={{ root: classes.priceContainer }}>
                    <Typography variant="body1">
                      {priceFormat(
                        fieldData.optionPrice,
                        fieldData.optionPriceInfo
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  classes={{ root: classes.descriptionContainer }}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      classes={{ root: classes.descriptionText }}
                    >
                      {fieldData.optionDescription}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ButtonBase>
          )}
        ></Controller>
      </FormGroup>
    </>
  )
}

export default CheckBoxField
