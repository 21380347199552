import React, { useEffect, useState } from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import { v4 as uuidv4 } from "uuid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"

import { CartContext } from "../../contexts"
import { addToCart, addSavedToCart } from "../../contexts/actions"
import FieldContainer from "./fieldContainer"
import FormInitialState from "./formInitialState"
import Counter from "./counter"
import ProductFooter from "../../components/product/productFooter"
import priceFormat from "../../helperFunc/priceFormat"
import {
  sort,
  updateRadio,
  updateBox,
  updateText,
  setSavedData,
} from "./formHelperFunc/formHelperFunc"
//import { price, two } from "./formHelperFunc/formHelperFunc"
//import testData from "../test/testData"
//import { link } from "gatsby"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: 0,
  },

  orderButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    paddingRight: "0.3rem",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",

    borderRadius: "4rem",

    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },

  orderButtonDisabled: {
    backgroundColor: `${theme.palette.common.grey} !important`,
    color: `${theme.palette.common.black} !important`,
    borderRadius: "4rem !important",
    borderColor: `${theme.palette.common.grey} !important`,
  },

  gridRight: {
    textAlign: "right",
  },

  gridLeft: {
    textAlign: "left",
  },

  gridCenter: {
    textAlign: "center",
  },

  priceChipEnabled: {
    paddingTop: "1.1rem",
    paddingBottom: "1.1rem",
    borderRadius: "4rem",
    marginLeft: "0.5rem",
    minWidth: "3rem",
    cursor: "pointer",
  },

  priceChipDisabled: {
    paddingTop: "1.1rem",
    paddingBottom: "1.1rem",
    borderRadius: "4rem",
    marginLeft: "0.5rem",
    minWidth: "3rem",
    backgroundColor: theme.palette.primary.light,
  },
  orderButt: {
    fontSize: "0.9rem",
    textTransform: "initial",
  },
  priceTextEnabled: {
    fontSize: "0.9rem",
    color: theme.palette.primary.main,
  },

  priceTextDisabled: {
    fontSize: "0.9rem",
    color: theme.palette.common.seventyWhite,
  },
}))

const FormContainer = ({
  dataIn,
  product,
  inStock,
  isSavedData,
  savedData,
}) => {
  const [isVisible, setIsVisible] = React.useState([])
  const [change, setChange] = React.useState(null)
  const [errors, setErrors] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [totalItemPrice, setTotalItemPrice] = useState(0)
  const [orderSelectedItems, setOrderSelectedItems] = useState({})
  const [data, setData] = useState([])
  const [isAllValid, setIsAllValid] = useState(false)
  const savedOrderSelectedItems = React.useRef({})

  const { cart, dispatchCart } = React.useContext(CartContext)

  const classes = useStyles()
  const methods = useForm()

  const formState = methods.formState.isValid
  const setValue = methods.setValue

  const onSubmit = data => {
    let sortedOrderSelectedItems = sort(orderSelectedItems)

    if (isSavedData) {
      dispatchCart(
        addSavedToCart(
          sortedOrderSelectedItems,
          quantity,
          totalItemPrice,
          savedData.itemId,
          savedData.product,
          savedData.product.name,
          savedData.product.strapiId,
          savedData.product.section,
          true
        )
      )
      navigate("/cart")
    } else {
      let productCopy = { ...product }
      productCopy.formData = [...dataIn]
      dispatchCart(
        addToCart(
          sortedOrderSelectedItems,
          quantity,
          totalItemPrice,
          uuidv4(),
          productCopy,
          product.name,
          product.strapiId,
          product.section,
          true
        )
      )
      navigate("/menu")
    }
  }

  //let dataIn = testData.checkBoxdata

  savedOrderSelectedItems.current = { ...cart[0]?.orderSelectedItems }

  useEffect(() => {
    if (isSavedData === true) {
      let dataInCopy = JSON.parse(JSON.stringify(savedData.product.formData))
      const res = setSavedData(dataInCopy, savedData.orderSelectedItems)
      setOrderSelectedItems(savedData.orderSelectedItems)
      setData(res.dataInCopy)
      setIsVisible(res.newIsVisible)
      setErrors(res.errorsArray)
      setQuantity(savedData.qty)
      setIsAllValid(true)
    } else {
      let dataInCopy = JSON.parse(JSON.stringify(dataIn))
      dataInCopy.sort((a, b) => (a.fieldOrder > b.fieldOrder ? 1 : -1))

      let errorsArray = []
      let newIsVisible = []
      for (let i = 0; dataInCopy.length > i; i++) {
        newIsVisible.push(dataInCopy[i].isVisible)
        errorsArray[i] = { isValid: false, errorMsg: null, count: 0 }
      }
      setData(dataInCopy)
      setIsVisible(newIsVisible)
      setErrors(errorsArray)
    }
  }, [dataIn, isSavedData, savedData])

  useEffect(() => {
    let newPrice = product.price

    for (let objKey of Object.keys(orderSelectedItems)) {
      newPrice = parseFloat(
        (newPrice + orderSelectedItems[objKey].fieldPrice).toFixed(2)
      )
      for (
        let i = 0;
        orderSelectedItems[objKey].selectedItems.length > i;
        i++
      ) {
        newPrice = parseFloat(
          (
            newPrice +
            orderSelectedItems[objKey].selectedItems[i].optionPrice *
              orderSelectedItems[objKey].selectedItems[i].quantity
          ).toFixed(2)
        )
      }
    }

    setTotalItemPrice(newPrice)
  }, [orderSelectedItems, product.price])

  useEffect(() => {
    if (change) {
      switch (change.method) {
        case "radio":
          updateRadio(
            change.optionIndex,
            setIsAllValid,
            change.name,
            orderSelectedItems,
            data[change.fieldIndex],
            data,
            setOrderSelectedItems,
            errors,
            setErrors,
            setValue,
            change.fieldIndex,
            isVisible,
            setIsVisible
          )
          break

        case "box":
          updateBox(
            change.value,
            change.optionIndex,
            setIsAllValid,
            orderSelectedItems,
            data[change.fieldIndex],
            data,
            setOrderSelectedItems,
            errors,
            setErrors,
            setValue,
            change.fieldIndex,
            change.addOrSub,
            isVisible,
            setIsVisible
          )
          break

        case "text":
          updateText(
            change.value,
            setIsAllValid,
            orderSelectedItems,
            data[change.fieldIndex],
            data,
            setOrderSelectedItems,
            errors,
            setErrors,
            change.fieldIndex,
            isVisible,
            setIsVisible
          )
          break
        default:
      }
      setChange(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change])

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        classes={{ root: classes.mainContainer }}
      >
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          {inStock === false ? (
            <></>
          ) : (
            <Grid item>
              <Grid container direction="column">
                {isVisible.length > 0 ? (
                  <FormInitialState
                    errors={errors}
                    setErrors={setErrors}
                    data={data}
                    setOrderSelectedItems={setOrderSelectedItems}
                    setIsAllValid={setIsAllValid}
                    isSavedData={isSavedData}
                  >
                    {data.map((form, index) => (
                      <FieldContainer
                        isVisible={isVisible[index]}
                        key={index}
                        formData={form}
                        fieldIndex={index}
                        setChange={setChange}
                      />
                    ))}
                  </FormInitialState>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}
          <ProductFooter>
            <Grid container alignItems="center">
              <Grid item classes={{ root: classes.gridLeft }}>
                {product.showQuantity ? (
                  <Counter
                    quantity={quantity}
                    setQuantity={setQuantity}
                    disabled={!inStock}
                  />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item classes={{ root: classes.gridCenter }}></Grid>

              <Grid item xs classes={{ root: classes.gridRight }}>
                <Button
                  disableElevation
                  variant="contained"
                  classes={{
                    root: classes.orderButton,
                    disabled: classes.orderButtonDisabled,
                  }}
                  disabled={
                    !isAllValid ||
                    !formState ||
                    inStock === false ||
                    totalItemPrice === 0
                  }
                  type="submit"
                >
                  <Typography
                    variant="body1"
                    classes={{ root: classes.orderButt }}
                  >
                    {isSavedData
                      ? "Update item"
                      : `Add ${
                          product.showQuantity ? `${quantity} ` : ""
                        }to order`}
                  </Typography>

                  <Chip
                    classes={{
                      root: clsx({
                        [classes.priceChipEnabled]: isAllValid || formState,
                        [classes.priceChipDisabled]:
                          !isAllValid || !formState || totalItemPrice === 0,
                      }),
                    }}
                    label={
                      <Typography
                        variant="body1"
                        classes={{
                          root: clsx({
                            [classes.priceTextEnabled]: isAllValid || formState,
                            [classes.priceTextDisabled]:
                              !isAllValid || !formState || totalItemPrice === 0,
                          }),
                        }}
                      >
                        {priceFormat(
                          parseFloat((quantity * totalItemPrice).toFixed(2)),
                          null,
                          true
                        )}
                      </Typography>
                    }
                  ></Chip>
                </Button>
              </Grid>
            </Grid>
          </ProductFooter>
        </form>
      </Grid>
    </>
  )
}

export default FormContainer
