import React, { memo } from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormGroup from "@material-ui/core/FormGroup"

import { Controller, useForm } from "react-hook-form"

import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

//import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import RadioField from "./radioField"
import QuantityBoxField from "./quantityBoxField"
import CheckBoxField from "./checkBoxField"
import TextBoxField from "./textBoxField"

import { useStyles } from "./formHelperFunc/formHelperFunc"

const FieldContainer = ({ formData, fieldIndex, setChange, isVisible }) => {
  const [count, setCount] = React.useState(0)
  const [fieldVisChanged, setFieldVisChanged] = React.useState(false)
  const rendCount = React.useRef(0)
  const classes = useStyles()
  const { control, setValue, reset } = useForm()

  rendCount.current = rendCount.current + 1

  React.useEffect(() => {
    if (formData.savedCount) {
      setCount(formData.savedCount)
    } else if (formData.defaultOption) {
      setCount(1)
    }
  }, [formData.defaultOption, formData.savedCount])

  React.useEffect(() => {
    if (!isVisible && rendCount.current > 1) {
      setFieldVisChanged(true)
      if (formData.defaultOption) {
        setCount(1)
      } else {
        setCount(0)
      }
      reset()
    }
  }, [isVisible, reset, formData.defaultOption])

  const radioChangeHandler = (name, optionIndex) => {
    if (count < 1) {
      setCount(1)
    }
    setChange({
      method: "radio",
      name: name,
      optionIndex: optionIndex,
      fieldIndex: fieldIndex,
    })
  }

  const updateTextFieldFunc = value => {
    if (count < 1 && value.length > 0) {
      setCount(1)
    } else if (count > 0 && value.length < 1) {
      setCount(0)
    }

    setChange({
      method: "text",
      value: value,
      fieldIndex: fieldIndex,
    })
  }

  const updateFormData = (value, optionIndex, addOrSub = null) => {
    if (!value || value < 1) {
      setCount(count - 1)
    } else {
      if (addOrSub) {
        setCount(count + addOrSub)
      } else {
        setCount(count + 1)
      }
    }

    setChange({
      method: "box",
      value: value,
      optionIndex: optionIndex,
      addOrSub: addOrSub,
      fieldIndex: fieldIndex,
    })
  }

  if (isVisible) {
    return (
      <Grid item>
        <Accordion elevation={0} square expanded={true}>
          <AccordionSummary
            aria-controls={`${formData.fieldDigitalLabel}-${formData.fieldName}-content`}
            id={`${formData.fieldDigitalLabel}-${formData.fieldName}-header`}
            classes={{
              root: clsx(classes.headerContainer, {
                [classes.headerContainerRed]:
                  count === 0 && formData.isRequired,
              }),
              content: classes.headerContentContainer,
            }}
          >
            <Box>
              <Typography variant="h6" classes={{ root: classes.headerText }}>
                {formData.fieldDigitalLabel}
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  {formData.isRequired ? (
                    <Chip
                      classes={{
                        root: clsx({
                          [classes.requiredChipSelected]: count > 0,
                          [classes.requiredChip]: count === 0,
                        }),
                      }}
                      color="secondary"
                      size="small"
                      label={
                        <Typography
                          variant="body1"
                          classes={{ root: classes.headerDetailsText }}
                        >
                          {"Required"}
                        </Typography>
                      }
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.headerDetailsText }}
                  >
                    {`${
                      formData.maxNumbOfSelections
                        ? `Choose up to ${formData.maxNumbOfSelections}`
                        : ""
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.detailsContainer }}>
            <Grid container direction="column">
              {formData.fieldType === "radio" ? (
                <FormControl>
                  <Controller
                    name={`${formData.fieldName}`}
                    control={control}
                    defaultValue={
                      formData.savedData && !fieldVisChanged
                        ? `${formData.fieldName}-${formData.savedData}`
                        : formData.defaultOption
                        ? `${formData.fieldName}-${formData.defaultOption}`
                        : null
                    }
                    render={({ field: { onChange, value, name, ref } }) => (
                      <RadioGroup value={value} ref={ref}>
                        {formData.optionsData.map((fieldData, index) => (
                          <RadioField
                            key={index}
                            onChange={onChange}
                            name={name}
                            optionIndex={index}
                            fieldData={fieldData}
                            fieldName={formData.fieldName}
                            radioChangeHandler={radioChangeHandler}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  ></Controller>
                </FormControl>
              ) : (
                <>
                  {formData.fieldType === "checkBox" ? (
                    <>
                      {formData.optionsData.map((fieldData, index) => (
                        <CheckBoxField
                          control={control}
                          key={index}
                          optionIndex={index}
                          fieldData={fieldData}
                          fieldName={formData.fieldName}
                          defaultOption={formData.defaultOption}
                          numberOfSelected={count}
                          maxNumbOfSelections={formData.maxNumbOfSelections}
                          updateFormData={updateFormData}
                          fieldVisChanged={fieldVisChanged}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  {formData.fieldType === "quantityBox" ? (
                    <>
                      {formData.optionsData.map((fieldData, index) => (
                        <QuantityBoxField
                          setValue={setValue}
                          control={control}
                          key={index}
                          optionIndex={index}
                          fieldData={fieldData}
                          fieldName={formData.fieldName}
                          defaultOption={formData.defaultOption}
                          numberOfSelected={count}
                          maxNumbOfSelections={formData.maxNumbOfSelections}
                          updateFormData={updateFormData}
                          fieldVisChanged={fieldVisChanged}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {formData.fieldType === "textField" ? (
                <Box classes={{ root: classes.formContainer }}>
                  <FormGroup>
                    <Controller
                      name={formData.fieldName}
                      control={control}
                      defaultValue={
                        formData.savedData && !fieldVisChanged
                          ? formData.savedData
                          : ""
                      }
                      rules={{
                        maxLength: formData.maxNumbOfSelections
                          ? formData.maxNumbOfSelections
                          : undefined,
                      }}
                      render={({
                        field: { value, onChange, ref },
                        fieldState: { error },
                      }) => (
                        <TextBoxField
                          value={value}
                          error={error}
                          inputRef={ref}
                          onChange={onChange}
                          formData={formData}
                          updateTextFieldFunc={updateTextFieldFunc}
                        />
                      )}
                    ></Controller>
                  </FormGroup>
                </Box>
              ) : (
                <></>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  } else {
    return null
  }
}

export default memo(FieldContainer)
